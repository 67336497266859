/* eslint-disable no-trailing-spaces */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-custom-tip',
  templateUrl: './custom-tip.page.html',
  styleUrls: ['./custom-tip.page.scss'],
})
export class CustomTipPage implements OnInit {
  @ViewChild(IonInput, { static: false }) input: IonInput;
  @Input('tip') tip;
  active = 'dollar';
  tipType = 'dollar';
  tipAmount;
  constructor(
    private modalController: ModalController,
    private globals: GlobalVariable
  ) {}

  ngOnInit() {}
  ionViewDidEnter() {
    if (this.tip) {
      this.tipAmount = this.tip;
    }

    setTimeout(() => {
      this.input.setFocus();
    }, 150);
  }
  close() {
    let tipData = {
      tip: 0,
      type: '',
      addTip: false
    };
    this.globals.isCustomTip = false;
    this.modalController.dismiss(tipData);
  }
  segmentChanged(e) {
    console.log(e.detail.value);
    this.tipAmount = 0;
    setTimeout(() => {
      this.input.setFocus();
    }, 150);
    this.active = e.detail.value;
    if (this.active == 'dollar') {
      this.tipType = 'dollar';
    } else {
      this.tipType = 'perc';
    }
  }
  addTip() {
    if (
      this.tipAmount === null ||
      this.tipAmount === undefined ||
      this.tipAmount === ''
    ) {
      this.globals.presentToast('Select Tip First');
      return;
    }
    let tipData = {
      tip: this.tipAmount,
      type: this.tipType,
      addTip: true
    };
    this.globals.isCustomTip = true;
    this.modalController.dismiss(tipData);
  }

  preventNegativeInput(event: any) {
    let inputValue = event.target.value;
  
    // Prevent typing negative values
    if (inputValue.startsWith('-')) {
      inputValue = inputValue.replace('-', '');
    }
  
    // Ensure only positive values are kept
    this.tipAmount = parseFloat(inputValue) || 0;
  }
}
